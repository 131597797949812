import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';

// Styles
import styles from './Cursor.module.css';
// Components
import { Picto } from '../Picto/Picto';

function Cursor({
  variants, cursorVariant, onClick,
}) {
  const { locale } = useRouter();
  return (
    <button type="button" onClick={onClick}>
      <motion.div
        className={styles.cursor}
        variants={variants}
        animate={cursorVariant}
      >
        <Picto icon={`tagPlayer-${locale}`} className={styles.videoIcon} />
      </motion.div>
    </button>
  );
}

Cursor.propTypes = {
  variants: PropTypes.shape({}),
  cursorVariant: PropTypes.string,
  onClick: PropTypes.func,
  // label: PropTypes.string,
};

Cursor.defaultProps = {
  variants: {},
  cursorVariant: '',
  onClick: () => {},
  // label: '',
};

export default Cursor;
